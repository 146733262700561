import { ExpandMore } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
	Button,
	Checkbox,
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FilterConditions } from "../../models/filter-condition.interface";
import { Personality } from "../../models/personality.interfaces";
import { FilterButtons } from "./FilterButtons";
import FilterPopover from "./FilterPopover";
import { useSharktank } from "../../helpers/sharktank-context"; // Ensure this import is present

export interface SubfilterItems {
	title: string;
	prop: keyof Personality;
	open: boolean;
	selected: string;
	items: string[];
}

const subfilterDefault: SubfilterItems[] = [
	{
		title: "Location",
		prop: "location",
		open: false,
		selected: "",
		items: ["San Francisco", "New York"],
	},
	{
		title: "Stage",
		prop: "stage",
		open: false,
		selected: "",
		items: ["Idea", "Pre-Seed", "Seed", "Series A"],
	},
	{
		title: "Vertical",
		prop: "vertical",
		open: false,
		selected: "",
		items: [],
	},
];

type FilterViewProps = {
	onChange?: (filter: FilterConditions) => void;
	config?: SubfilterItems[];
};

/**
 * Helper view to filter the avatars
 * @returns Buttons to filter the view
 */
export function SubfilterView({ onChange, config }: FilterViewProps) {
	// make filters useable in the view
	const [filters, setFilters] = useState(subfilterDefault);

	const toggleSubfilter = useCallback(
		(index: number) => {
			const newFilters = filters.map((filter, i) => {
				if (i === index) {
					filter.open = !filter.open;
				} else {
					filter.open = false;
				}
				return filter;
			});
			setFilters(newFilters);
			console.log("subfilter", newFilters);
		},
		[filters]
	);

	const handleCheckboxChange = useCallback(
		(index: number, value: string) => {
			// allow to unselect
			if (filters[index].selected === value) {
				value = "";
			}
			const newFilters = filters.map((filter, i) => {
				if (i === index) {
					filter.selected = value;
				}
				return filter;
			});
			setFilters(newFilters);
			console.log("checkbox", newFilters);

			// build filter conditions
			const newConditions: FilterConditions = newFilters.reduce(
				(prev: any, filter) => {
					prev[filter.prop] = {
						value: filter.selected,
						condition: "equals",
					};
					return prev;
				},
				{}
			);
			onChange && onChange(newConditions);
		},
		[filters, onChange]
	);

	useEffect(() => {
		const newConfig = config || [];

		// use default config if not provided
		subfilterDefault.forEach((subfilter) => {
			const found = (newConfig || []).find(
				(filter) => filter.prop === subfilter.prop
			);
			if (!found) {
				newConfig.push(subfilter);
			}
		});
		console.log("subfilter config", newConfig);
		setFilters(newConfig);
	}, [config]);

	// regex to remove special characters
	const getkey = useCallback(
		([...args]: string[]) =>
			args.map((arg) => arg.replace(/[^a-zA-Z0-9]/g, "_")).join("_"),
		[]
	);

	return (
		<List>
			{/* use filters to build listitems and list in collapse */}
			{filters.map((filter, index) => (
				<>
					<ListItem
						key={getkey([filter.prop, index + "lst"])}
						disablePadding
					>
						<ListItemButton onClick={() => toggleSubfilter(index)}>
							<ListItemText
								primary={
									<>
										{filter.title}
										{filter.selected && ": "}
										<strong>{filter.selected}</strong>
									</>
								}
							/>
							<ListItemIcon className="arrowIcon">
								{filter.open ? (
									<ExpandMore />
								) : (
									<KeyboardArrowRightIcon />
								)}
							</ListItemIcon>
						</ListItemButton>
					</ListItem>
					<Collapse
						key={getkey([filter.prop, index + "cpls"])}
						in={filter.open}
						timeout="auto"
						unmountOnExit
					>
						<List component="div" disablePadding>
							{filter.items.map((item) => (
								<ListItem
									key={getkey([filter.prop, item])}
									secondaryAction={
										<Checkbox
											edge="end"
											onChange={() =>
												handleCheckboxChange(
													index,
													item
												)
											}
											checked={filter.selected === item}
										/>
									}
									disablePadding
								>
									<ListItemButton sx={{ pl: 4 }}>
										<ListItemText primary={item} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Collapse>
				</>
			))}
		</List>
	);
}

/**
 * Helper view to filter the avatars
 * @returns Buttons to filter the view
 */
export function FilterView({ onChange, config }: FilterViewProps) {
	const { setSelectedCategory } = useSharktank(); // Access setSelectedCategory from context

	// state to manage filter
	const [category, setCategory] = useState("story_time");
	useEffect(() => {
		setSelectedCategory(category);
	}, [category, setSelectedCategory]);

	// state for filter conditions
	const [filters, setFilters] = useState<FilterConditions>({
		category: {
			value: category,
			condition: "equals",
		},
	});

	// update filter conditions
	useEffect(() => {
		onChange && onChange(filters);
	}, [filters, onChange]);

	// state to manage popover
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	/**
	 * Helper function to handle the change in filter
	 * @param event Mouse event
	 * @param selectedCategory New selection
	 */
	const categoryFilter = useCallback(
		(event: React.MouseEvent<HTMLElement>, selectedCategory: string) => {
			// assign filter value
			selectedCategory = selectedCategory || category;

			// Set the selected category in the context
			setSelectedCategory(selectedCategory); // Update the context with the selected category

			// handle more button
			if (selectedCategory.indexOf("_more") > -1) {
				const buttonEl = event.currentTarget as HTMLButtonElement;
				setAnchorEl(buttonEl);
			}

			// remove _more from filter and trigger change
			selectedCategory = selectedCategory.replace("_more", "");
			setCategory(selectedCategory);
			console.log("selected filter", selectedCategory, category);

			// update filter conditions
			const newConditions: FilterConditions = {
				...filters,
				category: {
					value: selectedCategory,
					condition: "equals",
				},
			};
			setFilters(newConditions);
			onChange && onChange(newConditions);
			console.log("filter conditions", newConditions);
		},
		[category, filters, onChange, setSelectedCategory] // Add setSelectedCategory to dependencies
	);

	const handleSubfilterChange = useCallback(
		(subfilters: FilterConditions) => {
			const newConditions: FilterConditions = {
				...filters,
				...subfilters,
			};
			Object.keys(newConditions).forEach((key) => {
				const prop = key as keyof FilterConditions;
				const subfilt = newConditions[prop] || null;

				if (!subfilt || !subfilt?.value) {
					delete newConditions[prop];
				}
			});

			setFilters(newConditions);
			onChange && onChange(newConditions);
			console.log("subfilter conditions", newConditions);
		},
		[filters, onChange]
	);

	const modalClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	return (
		<div className="filterButtons">
			<FilterButtons
				name="startup_advice"
				title="Startup Advice"
				filter={category}
				handleChange={categoryFilter}
			></FilterButtons>
			<FilterButtons
				name="health_and_wellness"
				title="Health and Wellness"
				filter={category}
				handleChange={categoryFilter}
			></FilterButtons>
			<FilterButtons
				name="family"
				title="Family"
				filter={category}
				handleChange={categoryFilter}
			></FilterButtons>
			<FilterButtons
				name="story_time"
				title="Story Time"
				filter={category}
				handleChange={categoryFilter}
			></FilterButtons>

			<FilterPopover
				id="filter_popover"
				anchorEl={anchorEl}
				onClose={modalClose}
			>
				<div className="popoverContent">
					<p className="listTitle">Add a filter</p>

					<SubfilterView
						onChange={handleSubfilterChange}
						config={config}
					/>

					<div className="listFooter">
						<Button
							color="secondary"
							variant="contained"
							className="confirmButton"
							onClick={modalClose}
						>
							OK!
						</Button>
					</div>
				</div>
			</FilterPopover>
		</div>
	);
}
