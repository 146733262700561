import Popover from "@mui/material/Popover";
import { useEffect, useState } from "react";

type FilterPopoverOptions = {
	id: string;
	anchorEl: HTMLButtonElement | null;
	children?: React.ReactNode;
	onClose?:
		| ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
		| undefined;
};

/**
 * Helper HTML to style the filter buttons
 * @returns Filter button group
 */
export default function FilterPopover({
	id,
	anchorEl,
	children,
	onClose,
}: FilterPopoverOptions) {
	// const open = Boolean(anchorEl);
	const [open, setOpen] = useState(false);
	useEffect(() => {
		setOpen(!!anchorEl);
	}, [anchorEl, setOpen]);

	return (
		<>
			{anchorEl && (
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={onClose}
					className="filterPopover"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					{children}
				</Popover>
			)}
		</>
	);
}
