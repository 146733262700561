import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import HomePageV2 from './pages/homev2';
import PitchSimulation from './pages/pitch-simulation';

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePageV2 />} />
				<Route path="/pitch/:personKey" element={<PitchSimulation />} />
				{/* <Route path="/v1" element={<HomePage />} /> */}
				{/* <Route path="/v1/:personKey" element={<Simulator />} /> */}
			</Routes>
		</Router>
	);
}
export default App;
