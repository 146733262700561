import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
	initializeFirestore,
	persistentLocalCache,
	persistentMultipleTabManager,
} from "firebase/firestore";
import { fetchConfig, getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	// process.env.FIREBASE_APIKEY not working right now
	apiKey: "AIzaSyBeEgytZ5NR6dPeXv0tmsu0uhwnUQFI3Do",
	authDomain: "axisai.firebaseapp.com",
	databaseURL: "https://axisai-default-rtdb.firebaseio.com",
	projectId: "axisai",
	storageBucket: "sharktank-demo",
	messagingSenderId: "745459079059",
	appId: "1:745459079059:web:931e45618b0d1772d4fa66",
	measurementId: "G-GQNMQ3LKMZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = initializeFirestore(app, {
	localCache: persistentLocalCache({
		tabManager: persistentMultipleTabManager(),
	}),
});

// Initialize storage
const storage = getStorage(app);

// load remote config from firebase
const config = getRemoteConfig(app);
config.defaultConfig = {};
config.settings.minimumFetchIntervalMillis = 1000;

// fetch and activate remote config
fetchConfig(config)
	.then(() => {
		console.log("fetchAndActivate database");
	})
	.catch((e) => {
		console.error("error fetching remote config", e);
	});

export { analytics, app, auth, config, db, storage };
