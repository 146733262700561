import { Avatar } from "@mui/material";
import { FilterConditions } from "../../models/filter-condition.interface";
import {
	Personalities,
	Personality,
} from "../../models/personality.interfaces";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

type AvatarItemProps = {
	id: string;
	item: Personality;
};

/**
 * Helper to style the avatar elements
 * @returns Return avatar HTML element
 */
export function AvatarItem({ id, item }: AvatarItemProps) {
	const navigate = useNavigate();
	const handleClick = useCallback(() => {
		console.log("Avatar clicked", item);
		navigate("/pitch/" + id);
	}, [id, item, navigate]);

	return (
		<div className="avatar" key={id}>
			<Avatar
				alt={item.name}
				src={item.imageUrl?.indexOf("http") === 0 ? item.imageUrl : ""}
				sx={{ width: 250, height: 250 }}
				className="avatarImage"
				onClick={handleClick}
			/>
			<p className="subtitle">
				<strong>{item.name}</strong>
				<br />
				{item.subtitle}
			</p>
		</div>
	);
}

type AvatarViewProps = {
	items: Personalities;
	filters?: FilterConditions;
};

/**
 * Helper to style the avatar elements
 * @param items Personalities collection from firebase
 * @returns View with collection of avatars
 */
export function AvatarView({ items, filters }: AvatarViewProps) {
	return (
		<div className="personalities">
			{Object.keys(items)
				.filter((personKey) =>
					Object.keys(filters || {})
						.map((property) => {
							// transform property to type PersonalityProps
							const prop = property as keyof Personality;
							const item = items[personKey];
							const value = item[prop];
							const filter = filters && filters[prop];

							// if prop is not on the item, return false
							if (!value || !filter) {
								return false;
							}

							// make a switch case to check the condition
							switch (filter.condition) {
								case "equals":
									if (Array.isArray(value)) {
										return value.indexOf(filter.value) > -1;
									}
									return value === filter.value;
								case "contains":
									if (
										typeof value === "string" ||
										Array.isArray(value)
									) {
										return value.indexOf(filter.value) > -1;
									}
									return false;

								default:
									return false;
							}
						})
						.reduce((prev, curr) => prev && curr, true)
				)
				.map((key) => (
					<AvatarItem key={key} id={key} item={items[key]} />
				))}
		</div>
	);
}
