import React, { useRef, useEffect } from "react";
import styles from "./CameraAvatar.module.scss";

const CameraAvatar: React.FC = () => {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		const getVideo = async () => {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: true,
				});
				if (videoRef.current) {
					videoRef.current.srcObject = stream;
				}
			} catch (err) {
				console.error("Error accessing the camera", err);
			}
		};

		getVideo();

		const videoElement = videoRef.current;
		return () => {
			if (videoElement && videoElement.srcObject) {
				const tracks = (
					videoElement.srcObject as MediaStream
				).getTracks();
				tracks.forEach((track) => track.stop());
			}
		};
	}, []);

	return (
		<div className={styles.cameraAvatar}>
			<video
				ref={videoRef}
				autoPlay
				playsInline
				muted
				className={styles.videoElement}
			/>
		</div>
	);
};

export default CameraAvatar;
