import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
import App from './App';
import { FirebaseProvider } from "./helpers/firebase-context";
import { SharktankProvider } from "./helpers/sharktank-context";
import './index.css';
import reportWebVitals from './reportWebVitals';

const helmetContext = {};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<HelmetProvider context={helmetContext}>
			<FirebaseProvider>
				<SharktankProvider>
					<App />
				</SharktankProvider>
			</FirebaseProvider>
		</HelmetProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
