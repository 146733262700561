// Import the functions you need from the SDKs you need
import PersonIcon from "@mui/icons-material/Person";
import { Box, Button, Fab, Modal, Typography, TextField } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { GoogleAuthProvider, User } from "firebase/auth";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import * as firebaseui from "firebaseui";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useFirebase } from "../helpers/firebase-context";
import { useSharktank } from "../helpers/sharktank-context";
import { FilterConditions } from "../models/filter-condition.interface";
import { AvatarView } from "./elements/AvatarView";
import { FilterView, SubfilterItems } from "./elements/FilterView";
import "./homev2.scss";
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Page title
const TITLE = "Axis AI | Digital Twins You Can Video Chat";

/**
 * Helper to style the login elements
 * @returns LoginView
 */
const LoginView = () => {
	return (
		<>
			<Typography id="modal-modal-title" variant="h6" component="h2">
				Please login to access the app
			</Typography>
			<div id="firebaseui-auth-container"></div>
		</>
	);
};

/**
 * Helper to style the UI if user is logged in
 * @param user User object from firebase
 * @returns User view
 */
const Userview = ({ user }: { user: User }) => {
	const { auth } = useFirebase();
	return (
		<>
			<Typography id="modal-modal-title" variant="h6" component="h2">
				Welcome {user.displayName}
			</Typography>
			<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
				Loggen in as {user.email}
			</Typography>
			<Button variant="outlined" onClick={() => auth.signOut()}>
				Logout
			</Button>
		</>
	);
};

/**
 * Homepage v2
 * @returns HomePage for the second
 */
const HomePageV2 = () => {
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState<User | null>(null);

	// state for filter conditions
	const [filters, setFilters] = useState<FilterConditions>({});

	// state to manage filter remove config
	const [filterConfig, setFilterConfig] = useState<SubfilterItems[]>([]);

	// Initialize firebase reference
	const { analytics, auth, config } = useFirebase();

	// initialize sharktank context
	const { personalities } = useSharktank();

	// State for story modal
	const [storyModalOpen, setStoryModalOpen] = useState(false);
	const [storyTitle, setStoryTitle] = useState('');
	const [storyContent, setStoryContent] = useState('');
	const [storyAuthor, setStoryAuthor] = useState('');

	/**
	 * Open and close modal
	 */
	const openLoginModal = useCallback(() => {
		setOpen(true);

		setTimeout(() => {
			if (!user) {
				// check if user is logged in
				// Initialize the FirebaseUI Widget using Firebase.
				var ui = new firebaseui.auth.AuthUI(auth);

				// The start method will wait until the DOM is loaded.
				ui.start("#firebaseui-auth-container", {
					callbacks: {
						signInSuccessWithAuthResult: function (
							authResult,
							redirectUrl
						) {
							// User successfully signed in.
							// Return type determines whether we continue the redirect automatically
							// or whether we leave that to developer to handle.
							console.log("authResult", authResult, redirectUrl);
							// track event for successful login
							logEvent(analytics, "login", {
								method: "Google",
							});
							return true;
						},
						uiShown: function () {
							// The widget is rendered.
							// Hide the loader.
						},
					},
					// Will use popup for IDP Providers sign-in flow instead of the default, redirect.
					signInFlow: "popup",
					signInOptions: [GoogleAuthProvider.PROVIDER_ID],
				});
			}
		}, 100);
	}, [user, analytics, auth]);

	const closeLoginModal = useCallback(() => {
		setOpen(false);

		// check if user is logged in
		if (!user) {
			openLoginModal();
		}
	}, [user, openLoginModal]);

	/**
	 * Log page view event
	 */
	useEffect(() => {
		console.log("try to login user");

		// check if user is logged in
		const unsubscribe = auth.onAuthStateChanged((authuser) => {
			if (authuser) {
				console.log("logged in", authuser);
				setUser(authuser);
			} else {
				// User is signed out.
				console.log("user is signed out");
				// logout user
				setUser(null);
				// open the modal
				openLoginModal();
			}
		});

		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, [openLoginModal, auth]);

	useEffect(() => {
		logEvent(analytics, "page_view", { page: "homepage_v2" });
		if (user) {
			// load remote config
			console.log("fetchAndActivate database");
			fetchAndActivate(config).then(() => {
				const configVal = getValue(config, "sharktank_subfilter");
				try {
					const config = JSON.parse(configVal.asString());
					console.log("sharktank_subfilter config", config);
					setFilterConfig(config);
				} catch (e) {
					console.error("error parsing remote config", e);
				}
			});
		}
	}, [user, config, analytics]);

	console.log(">> filters", filters.category);
	
	const openStoryModal = () => {
		console.log('Opening story modal'); // Add this for debugging
		setStoryModalOpen(true);
	};

	const closeStoryModal = () => {
		setStoryModalOpen(false);
		setStoryTitle('');
		setStoryContent('');
		setStoryAuthor('');
	};

	const pushToFirebasePersonalities = async (storyData: {
		name: string;
		transcript: string;
		subtitle: string;
		imageUrl: string;
		category: string;
		voiceId: string;
		selfies: string[];
	}) => {
		const auth = getAuth();
		const db = getFirestore();

		if (!auth.currentUser) {
			throw new Error('User must be logged in to submit a story');
		}

		try {
			const docRef = await addDoc(collection(db, 'personalities'), {
				...storyData,
				userId: auth.currentUser.uid,
				createdAt: new Date(),
				type: 'story',
			});
			console.log('Story added with ID: ', docRef.id);
			return docRef.id;
		} catch (error) {
			console.error('Error adding story: ', error);
			throw error;
		}
	};

	const handleStorySubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await pushToFirebasePersonalities({
				name: storyTitle,
				transcript: storyContent,
				subtitle: storyAuthor,
				imageUrl: "my_story.png",
				category: "story_time",
				voiceId: "tTAYrOjOGNVhYiWb2QA0",
				selfies: ["amanda_peterson.png"]
			});
			closeStoryModal();
			// Optionally, show a success message to the user
		} catch (error) {
			console.error('Error submitting story:', error);
			// Optionally, show an error message to the user
		}
	};

	return (
		<div className="main">
			{/* load firebase UI styles */}
			<Helmet>
				<title>{TITLE}</title>
				<link
					type="text/css"
					rel="stylesheet"
					href="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css"
				/>
				<link
					rel="stylesheet"
					href="https://fonts.googleapis.com/icon?family=Material+Icons"
				/>
			</Helmet>

			{/* header */}
			<div className="header">
				<Fab
					onClick={openLoginModal}
					color="primary"
					aria-label="add"
					className="loginButton"
				>
					<PersonIcon />
				</Fab>
			</div>

			{/* filter view */}
			<div className="filter">
				<FilterView onChange={setFilters} config={filterConfig} />
			</div>

			{/* list of avatars */}
			<div className="avatarContainer">
				<AvatarView items={personalities} filters={filters} />
				{filters.category?.value === 'story_time' && (
					<IconButton
						onClick={openStoryModal}
						sx={{
							width: 80,
							height: 80,
							backgroundColor: '#FFF9C4', // Soft yellow color
							'&:hover': {
								backgroundColor: '#FFF59D', // Slightly darker yellow on hover
							},
							marginTop: '20px',
						}}
					>
						<AddIcon sx={{ fontSize: 40, color: '#424242' }} />
					</IconButton>
				)}
			</div>

			<Modal
				open={storyModalOpen}
				onClose={closeStoryModal}
				aria-labelledby="story-modal-title"
				aria-describedby="story-modal-description"
			>
				<Box sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					maxHeight: '75vh', // 75% of the viewport height
					bgcolor: 'background.paper',
					boxShadow: 24,
					borderRadius: 2,
					display: 'flex',
					flexDirection: 'column',
				}}>
					<Box sx={{ p: 3, borderBottom: '1px solid #e0e0e0' }}>
						<Typography id="story-modal-title" variant="h6" component="h2">
							Create a Story
						</Typography>
					</Box>
					<Box sx={{ 
						flexGrow: 1, 
						overflowY: 'auto',
						p: 3,
					}}>
						<form onSubmit={handleStorySubmit}>
							<Typography variant="subtitle1" sx={{ mb: 1 }}>
								What do you want the title of your story to be?
							</Typography>
							<TextField
								fullWidth
								label=""
								placeholder="Enter your story title"
								variant="outlined"
								value={storyTitle}
								onChange={(e) => setStoryTitle(e.target.value)}
								margin="normal"
							/>
							<Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
								Who is the author of this story?
							</Typography>
							<TextField
								fullWidth
								label=""
								placeholder="Enter the author's name"
								variant="outlined"
								value={storyAuthor}
								onChange={(e) => setStoryAuthor(e.target.value)}
								margin="normal"
							/>
							<Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
								Enter your story content:
							</Typography>
							<TextField
								fullWidth
								label=""
								placeholder="Write your story here"
								variant="outlined"
								multiline
								rows={4}
								value={storyContent}
								onChange={(e) => setStoryContent(e.target.value)}
								margin="normal"
							/>
							<Button 
								type="submit" 
								variant="contained" 
								color="primary"
								sx={{ mt: 2 }}
							>
								Submit Story
							</Button>
						</form>
					</Box>
				</Box>
			</Modal>

			{/* open modal button */}
			<Modal
				open={open}
				onClose={closeLoginModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box className="loginModal">
					{user ? <Userview user={user} /> : <LoginView />}
				</Box>
			</Modal>
		</div>
	);
};

export default HomePageV2;
