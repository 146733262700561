import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";

/**
 * Helper HTML to style the filter buttons
 * @param param0 Parameters to control the filter buttons
 * @returns Filter button group
 */
export const FilterButtons = ({
	name,
	title,
	filter,
	handleChange,
}: {
	[k: string]: any;
}) => {
	const [selected, setSelected] = useState(false);
	useEffect(() => {
		setSelected((filter || "").indexOf(name) > -1);
		// console.log("selected", selected, filter, name);
	}, [filter, name, selected]);

	return (
		<ToggleButtonGroup
			color="primary"
			value={filter}
			exclusive
			onChange={handleChange}
			aria-label="Filter"
			className="filterButtonGroup"
		>
			<ToggleButton
				selected={(filter || "").indexOf(name) > -1}
				value={name}
			>
				{title}
			</ToggleButton>
			<ToggleButton
				selected={(filter || "").indexOf(name) > -1}
				value={name + "_more"}
			>
				<MoreVertIcon />
			</ToggleButton>
		</ToggleButtonGroup>
	);
};
