import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from "react";
import { analytics, app, auth, config, db, storage } from "./firebase-config";
import { User, onAuthStateChanged, signOut } from "firebase/auth";
import { fetchAndActivate, getValue } from "firebase/remote-config";

type ConfigValueType = "string" | "json" | "boolean" | "number";

type ConfigValueTypes = {
	string: string;
	json: Record<string, any>;
	boolean: boolean;
	number: number;
};

type ContextProps = {
	user: User | null;
	db: typeof db;
	storage: typeof storage;
	logOut: () => Promise<void>;
	config: typeof config;
	app: typeof app;
	auth: typeof auth;
	analytics: typeof analytics;
	getConfig: <T extends ConfigValueType>(
		key: string,
		type: T
	) => Promise<ConfigValueTypes[T]>;
};

// create firebase context
const FirebaseContext = createContext<ContextProps | undefined>(undefined);

export const useFirebase = (): ContextProps => {
	const context = useContext(FirebaseContext);
	if (!context) {
		throw new Error("useFirebase must be used within a FirebaseProvider");
	}
	return context;
};

type FirebaseProviderProps = {
	children: React.ReactNode;
};

export const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
	const [user, setUser] = useState<User | null>(null);
	// const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (authuser) => {
			console.log("authuser", authuser);
			setUser(authuser);
			// setLoading(false);
		});

		return () => unsubscribe();
	}, []);

	const logOut = async () => {
		await signOut(auth);
	};

	/**
	 * Helper method to get remote config
	 */
	const getConfig = useCallback(
		<T extends ConfigValueType>(
			key: string,
			type: T
		): Promise<ConfigValueTypes[T]> =>
			fetchAndActivate(config).then((state) => {
				const configVal = getValue(config, key);
				// console.log("configVal", state, type, configVal, config);
				if (type === "json") {
					try {
						return JSON.parse(configVal.asString());
					} catch (e) {
						console.error("error parsing remote config", e);
						return {};
					}
				} else if (type === "boolean") {
					return configVal.asBoolean();
				} else if (type === "number") {
					return configVal.asNumber();
				} else {
					return configVal.asString();
				}
			}),
		[]
	);

	const value = {
		user,
		db,
		storage,
		logOut,
		config,
		app,
		auth,
		analytics,
		getConfig,
	};

	return (
		<FirebaseContext.Provider value={value}>
			{/* {!loading && children} */}
			{children}
		</FirebaseContext.Provider>
	);
};
